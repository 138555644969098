import React from "react";
import map from "../../assets/images/icons/map.svg";
import phone from "../../assets/images/icons/phone.svg";
import email from "../../assets/images/icons/email.svg";

const ContactBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content width-960 margin-auto">
            <div className="contacts-container flex">
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={map} alt="Map" /> <h2>Address</h2>
                </div>
                <div className="contact-item-body">
                  <p>Nantabulirwa, Goma Division</p>
                  <p>P.O Box 150154, Mukono, Uganda</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={email} alt="Email" /> <h2>Email</h2>
                </div>
                <div className="contact-item-body">
                  <p>contact@jmstonelogistics.com</p>
                  <p>info@jmstonelogistics.com</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={phone} alt="Phone" /> <h2>Phone Numbers</h2>
                </div>
                <div className="contact-item-body">
                  <p>+256 (770) 882 236</p>
                </div>
              </div>
            </div>
            <div className="map-container width-100 section-spacing-50">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.742615266058!2d32.72608407496476!3d0.3529589996435797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMjEnMTAuNyJOIDMywrA0Myc0My4yIkU!5e0!3m2!1sen!2sug!4v1708492163869!5m2!1sen!2sug"
                style={{ border: 0 }}
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="address-message flex justify-center section-spacing-30">
              <a href="#" className="button special-blue-bg white boldTitle">
                Write To Us!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBody;
